import { domReady, transitionEnter, transitionLeave } from './helpers';
import { computePosition, arrow, shift, autoUpdate } from '@floating-ui/dom';

// Please report any new value to assets/css/shared/_menu.scss (var $menu-breakpoint)
const mobileBreakPoint = 1360;

domReady(() => {
  const nav = document.querySelector('#navbar');

  let autoUpdateCleaner = null;

  function update(opener) {
    const arrowEl = opener.parentNode.querySelector('.arrow');
    const subMenu = opener.parentNode.querySelector('.sub-menu');

    const arrowLen = arrowEl.offsetWidth;

    autoUpdateCleaner = autoUpdate(opener, subMenu, async () => {
      const { x, y, middlewareData } = await computePosition(opener, subMenu, {
        placement: 'bottom-start',
        middleware: [shift({ padding: 30 }), arrow({ element: arrowEl })],
      });

      Object.assign(subMenu.style, {
        left: `${x}px`,
        top: `${y}px`,
      });

      if (middlewareData.arrow) {
        const { x, y } = middlewareData.arrow;

        Object.assign(arrowEl.style, {
          left: x != null ? `${x}px` : '',
          top: `${-arrowLen / 2}px`,
        });
      }
    });
  }

  if (nav) {
    /*
     * Main menu navigation
     */
    let currentOpened; // remember the currently opened submenu

    async function open(submenu, options) {
      currentOpened = submenu;

      transitionEnter(submenu, options);
      update(submenu);
    }

    async function close(options) {
      if (currentOpened) {
        transitionLeave(currentOpened, options);
        currentOpened = null;
      }

      if (autoUpdateCleaner) {
        autoUpdateCleaner();
        autoUpdateCleaner = null;
      }
    }

    document.addEventListener('click', async (evt) => {
      const { target } = evt;

      // submenu opener clicked
      if (target.classList.contains('sub-menu__opener')) {
        // no opened submenu yet
        if (!currentOpened) {
          open(target);
        }
        // this specific submenu is already opened
        else if (currentOpened === target) {
          close();
        }
        // another submenu already opened
        else {
          close({ transition: false });
          open(target, { transition: false });
        }
      }
      // submenu closer clicked
      else if (target.closest('.sub-menu__closer')) {
        close();
      }
      // click outside
      else if (!target.closest('#navbar')) {
        close();
      }
    });

    let timeout;

    for (const opener of nav.querySelectorAll('.sub-menu__opener')) {
      opener.parentNode.addEventListener('mouseover', async () => {
        // Disable mouse events on mobile to not interfere during responsive development
        if (window.innerWidth < mobileBreakPoint) {
          return;
        }

        // timeout presence means the user is switching directly from one menu to another
        if (timeout) {
          // cancel the ongoing animated close
          clearTimeout(timeout);
          timeout = null;
          // close with no animation
          await close({ transition: false });
          // Open the new menu with no animation
          open(opener, { transition: false });
        } else {
          open(opener);
        }
      });

      opener.parentNode.addEventListener('mouseout', async () => {
        // Disable mouse events on mobile to not interfere during responsive development
        if (window.innerWidth < mobileBreakPoint) {
          return;
        }

        // this timeout will be used to detect if the user is switching directly from one menu to another
        timeout = setTimeout(() => {
          close();
          timeout = null;
        }, 100);
      });
    }

    /*
     * Mobile menu toggle
     */
    const mainMenu = nav.querySelector('.main-menu');
    nav
      .querySelector('.main-menu__hamburger button')
      .addEventListener('click', async (evt) => {
        const { currentTarget } = evt;

        if (currentTarget.classList.contains('toggled')) {
          currentTarget.classList.remove('toggled');
          await transitionLeave(mainMenu);
          close();
        } else {
          transitionEnter(mainMenu);
          currentTarget.classList.add('toggled');
        }
      });
  }
});
